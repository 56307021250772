<template>
	<div>
		<h3 class="focus">스테이킹 이력</h3>
		<div class="mt-10">
			<div
				v-if="items.length > 0"
			>
				<table
					class="table"
				>
					<colgroup>
						<col style="width: 25%">
						<col style="width: 25%">
						<col style="width: 25%">
						<col style="width: 25%">
					</colgroup>
					<thead>
						<tr>
							<th>일시</th>
							<th>Mining</th>
							<th>일시</th>
							<th>Mining</th>
						</tr>
					</thead>
				</table>
				<ul>
					<li
						v-for="(item, index) in items"
						:key="'item_' + index"
						class="inline-block width-50 under-line box-staking-history"
					>
						<div class="flex-row justify-space-between pa-20 ">
							<div class="flex-1 text-center">{{ item.reward_daytime }}</div>
							<div class="flex-1 text-center">{{  item.reward_token_quantity }}</div>
						</div>
					</li>
					<li
						v-if="items.length % 2 != 0"
						class="inline-block width-50 under-line "
					>
						<div class="flex-row justify-space-between pa-20 color-white">1
						</div>
					</li>
				</ul>
			</div>
			<div
				v-else
				class="mt-30 none"
			>스테이킹 이력이 없습니다</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia038'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'STAKING 내역'
			}
			, items: []
		}
	}
	,methods: {
		getData: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_mining_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					, type: true
				})
				if(result.success){
					this.items = result.data.stakg_nft_card_reward_list
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
	.box-staking-history:not(:nth-child(2n)) {
		border-right: 1px solid #ddd; box-sizing: border-box
	}
</style>