<template>
	<div>
		<ul
			v-if="items.length > 0"
		>
			<li
				v-for="(item, index) in items"
				:key="'item_' + index"
				class="inline-block mb-10 box-staking"

				@click="toDetail(item)"
			>
				<div class="position-relative">
					<img
						:src="$request.upload_url(item.nft_card_img_url)"
						:alt="item.name"
						class="width-100"
					>
					<div class="bg-lock position-absolute-full radius-20"></div>
				</div>
			</li>
		</ul>

		<div
			v-else
			class="mt-30 none"
		>스테이킹 이력이 없습니다</div>
	</div>
</template>

<script>

export default {
	name: 'mafia037'
	, props: ['user']
	, components: {}
	, data: function(){
		return {
			program:{
				name: 'STAKING NFT'
			}
			, items: []
			, item_search: {
				page: 1
				, list_cnt: 10
			}
		}
	}
	, methods: {
		getData: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.get_staking_nft
					,data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})

				if(result.success){
					if(this.items.length > 0){
						this.items = this.items.concat(result.data.stakg_nft_card_list)
					}else{
						this.items = result.data.stakg_nft_card_list
					}
					if(result.data.stakg_nft_card_list.length > 0){
						this.item_search.page++
						await this.getData()
					}
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message})
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			console.log(item)
			this.$bus.$emit('to', { name: 'mafia0362', params: { card_type: 'union', id: item.nft_card_number}})
		}
	}
	, created() {
		console.log(this.items)
		this.getData()
	}
}
</script>
<style>
	.box-staking {
		width: 210px;
	}

	.box-staking:not(:nth-child(4n)) {
		margin-right: 10px;
	}
</style>