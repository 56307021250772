<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<div class="flex-side mr-30">
			<div class="under-line pb-10 flex-row">
				<h3 class="flex-1">STAKGING - MINING</h3>
			</div>

			<div>
				<div class="roket_warp bg-black">
					<div class="roket_area">
						<div class="roket-container">

							<div class="wing-left"></div>
							<div class="wing-right"></div>
							<div class="exhaust"></div>

							<div class="capsule">
								<div class="top">
									<div class="shadow"></div>
								</div>
								<div class="base"></div>
							</div>

							<div class="window-big"></div>
							<div class="window-small"></div>

							<div class="fire-1"></div>
							<div class="fire-2"></div>
							<div class="fire-3"></div>
							<div class="fire-4"></div>

							<div class="spark-1"></div>
							<div class="spark-2"></div>
							<div class="spark-3"></div>
							<div class="spark-4"></div>

							<div class="star star-1"></div>
							<div class="star star-2"></div>
							<div class="star star-3"></div>
							<div class="star star-4"></div>
							<div class="star star-5"></div>
							<div class="star star-6"></div>
							<div class="star star-7"></div>
							<div class="star star-8"></div>
							<div class="star star-9"></div>
							<div class="star star-10"></div>
							<div class="star star-11"></div>
							<div class="star star-12"></div>
							<div class="star star-13"></div>
							<div class="star star-14"></div>
							<div class="star star-15"></div>
							<div class="star star-16"></div>
							<div class="star star-17"></div>
							<div class="star star-18"></div>
							<div class="star star-19"></div>
							<div class="star star-20"></div>

						</div>
					</div>
				</div>
				<div class="mined">
					<span class="tit">TOTAL CUMULATIVE MINING</span>
					<strong class="min_count">{{ Number(item_main.tot_reward_token_quantity).toFixed(2) }}</strong>
				</div>

				<hr class="mt-20 under-line"/>


				<div class="mt-20 mining">
					<span class="tit">TODAY</span>
					<strong class="min_count">{{ Number(item_main.today_reward_token_quantity).toFixed(2) }}</strong>
				</div>

				<div class="mt-30 text-center">
					<button
						class="btn-inline btn-primary radius-20 mr-10"
						@click="to0361"
					>보상 인출</button>
					<button
						class="btn-inline btn-primary radius-20"
						@click="to0193"
					>인출 내역</button>
				</div>
			</div>

		</div>

		<div class="flex-1">

			<div class="under-line pb-10 flex-row">
				<h3 class="flex-1">STAKING - LIST</h3>
			</div>

			<mafia037
				:user="user"

				@cancel="is_037 = false"

				class="mt-20"
			></mafia037>

		</div>

		<mafia038
			v-if="is_038"
			:user="user"

			@cancel="is_038 = false"
		></mafia038>

		<mafia0361
			v-if="is_0361"
			:user="user"

			@cancel="is_0361 = false"
		></mafia0361>

		<mafia0193
			v-if="is_0193"
			:user="user"

			@cancel="is_0193 = false"
		></mafia0193>
	</div>
</template>

<script>
	import mafia037 from '@/view/Staking/mafia037'
	import mafia038 from '@/view/Staking/mafia038'
	import Mafia0361 from "@/view/Staking/mafia036-1";
	import Mafia0193 from "@/view/Staking/mafia019-3";

	export default {
		name: 'mafia036'
		, props: ['user']
		, components: {Mafia0193, Mafia0361, mafia037, mafia038 }
		, data: function(){
			return {
				program: {
					name: '스테이킹'
					, title: 'STAKING'
				}
				, mining: {
					total: 0
					, today: 0
					, count: 0
				}
				, is_038: false
				, is_037: false
				, is_0361: false
				, item_main: {
					tot_reward_token_quantity: 0
					, today_reward_token_quantity: 0
				}
				, is_0193: false
			}
		}
		, methods: {
			getData: async function(){
				try{
					// this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_staking_main
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item_main = result.data
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, to0361: function(){
				this.is_0361 = true
			}
			, to0193: function(){
				this.is_0193 = true
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>